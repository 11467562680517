import React from 'react';
import { Box } from '@chakra-ui/react';
import SelectAsync from 'react-select/async';
import { CFormError } from '../../../../../Typography';
import { OptionType, selectValue } from '../../../../Types';
import { VirtualizedMenuList } from '../../atoms/VirtualizedMenuList.atom';
import {
    useSelectFieldTheme,
    useSelectPaginatedField,
} from '../../SelectField.hook';
import {
    InputActions,
    PaginatedMultiSelectFieldProps,
} from '../../SelectField.types';
import { Opacity } from '../../../../../ChakraTheme';
import { OptionWithTooltip } from '../../atoms/OptionWithToolTip.atom';
import { GroupHeading } from '../../atoms/GroupHeading.atom';

export const CSelectFieldPaginated = <T extends selectValue = string | number>({
    testId,
    onChange,
    onClose,
    errorText,
    name,
    inputValue,
    hideError = false,
    isMulti,
    closeMenuOnSelect,
    components,
    height,
    minWidth,
    dependsOn,

    isDisabled,
    placeholder,
    width,
    maxWidth,
    resetSearchOnSelection = false,
    shouldWrap,
    fetchOptions,
    trackedPagination,
    ...props
}: PaginatedMultiSelectFieldProps<T>): JSX.Element => {
    const { styles, theme } = useSelectFieldTheme({
        height,
        errorText,
        shouldWrap,
        isMulti,
    });

    const {
        portal,
        open,
        setOpen,
        valueProp,
        searchValue,
        options,
        isLoading,
        handleSearchChange,
        handleMenuOpen,
        debouncedChange,
        fetchAndSetOptions,
    } = useSelectPaginatedField({
        onChange,
        inputValue,
        isMulti,
        resetSearchOnSelection,
        dependsOn,
        trackedPagination,
        fetchOptions,
    });

    return (
        <Box
            data-test={testId}
            maxWidth={maxWidth}
            minWidth={`${minWidth ?? 150}px`}
            width={width}
            opacity={isDisabled ? Opacity.High : 'unset'}
            cursor={isDisabled ? 'not-allowed' : 'unset'}
        >
            <SelectAsync<OptionType>
                {...props}
                {...valueProp}
                defaultOptions={options}
                components={{
                    GroupHeading,
                    Option: OptionWithTooltip,
                    ...components,
                    MenuList: VirtualizedMenuList,
                }}
                isLoading={isLoading}
                cacheOptions
                loadOptions={(v) =>
                    handleSearchChange(v, { action: InputActions.inputChange })
                }
                options={options}
                onChange={debouncedChange}
                onMenuScrollToBottom={() => fetchAndSetOptions()}
                onInputChange={handleSearchChange}
                inputId={name}
                inputValue={searchValue}
                styles={styles}
                theme={theme}
                menuPortalTarget={portal}
                isMulti={isMulti}
                closeMenuOnSelect={isMulti ? false : closeMenuOnSelect}
                hideSelectedOptions={false}
                menuPlacement="auto"
                isDisabled={isDisabled}
                placeholder={placeholder}
                menuIsOpen={open}
                onMenuOpen={handleMenuOpen}
                onMenuClose={() => {
                    setOpen(false);
                    onClose?.();
                }}
            />
            {!hideError && (
                <CFormError data-test={`${name}--error`} text={errorText} />
            )}
        </Box>
    );
};
